.logo {
  width: 50px;
  height: 50px;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: "Mulish", "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

main {
  margin-top: 50px;
  /* background-color: rgb(246,245,248); */
  background-color: white;
  color: #32373b;
}
main.horizFlex {
  margin-top: 64px;
}
main .notFound {
  margin-top: 60px;
  text-align: center;
}
a:hover {
  opacity: 0.8;
  color: white;
}
.affiliate:hover {
  color: #06f;
}
footer {
  height: 40px;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  align-items: center;
  display: flex;
  animation: fadein 2s;
  height: auto;
  animation: rainbow 16s infinite;
  max-height: 50px;
}

.topnav,
.resNav {
  display: flex;
  margin: auto 0;
  flex: 1;
  align-self: center;
  align-self: flex-end;
  overflow: hidden;
}

.topnav a,
.resNav a,
.topnav #btnTranslate {
  /* float: right; */
  font-size: medium;
  font-weight: bold;
  flex: 1;
  display: block;
  color: black;
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
  border-radius: 20px 20px 0 0;
  cursor: pointer;
  margin-top: 8px;
}

.topnav a {
  margin-right: 8px;
}
.topnav a:not(.active),
.topnav #btnTranslate {
  border-radius: 20px;
}
.topnav a.active {
  margin-top: 8px;
}
.topnav a:hover,
.resNav a:hover,
.horiz :hover,
.topnav #btnTranslate:hover {
  background-color: rgb(64, 195, 255);
  color: white;
}
.topnav {
  margin-left: 8px;
}
@keyframes rainbow {
  from,
  to {
    box-shadow: 0 0 15px 0 red;
  }
  16% {
    box-shadow: 0 0 15px 0 yellow;
  }
  32% {
    box-shadow: 0 0 15px 0 green;
  }
  48% {
    box-shadow: 0 0 15px 0 aqua;
  }
  64% {
    box-shadow: 0 0 15px 0 blue;
  }
  80% {
    box-shadow: 0 0 15px 0 fuchsia;
  }
}
.topnav a.active,
.resNav a.active {
  background-color: #21b1f3;
  color: white;
  border-radius: 20px;
}

.topnav .icon {
  display: none;
}

#resTopnav {
  display: none;
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 0;
  right: 0;
  background-color: black;
  background-image: linear-gradient(
    to right,
    rgb(159, 209, 123),
    rgb(64, 195, 255),
    rgb(254, 123, 230)
  );
  /* transition: 0.5s; */
  width: 100%;
}

#closeNav {
  font-size: x-large;
  text-align: right;
  cursor: pointer;
  padding-right: 20px;
  display: none;
}

#closeNav:hover {
  background-color: rgb(235, 195, 133);
  color: rgb(36, 36, 36);
}

@media screen and (max-width: 600px) {
  /* .topnav a:not(:first-child) {display: none;} */
  /* .closeNav{
    display: block;
  } */
  .topnav a {
    display: none;
  }
  .topnav .icon {
    float: right;
    display: block;
  }
  .info {
    display: block;
    /* width: 98%;
    margin: auto;
    margin-top: 10px;
    justify-content: center;
    background-color: red;
    align-items: center; */
  }
}

/* .mySlides {
  display:none;
} */

video {
  width: 100%;
  height: 300px;
}
.slideshow-container img {
  margin: auto;
  height: 200px;
  /* width: fit-content; */
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  background: #21b1f3;
  height: 200px;
  overflow: hidden;
  animation: fadein 4s;
  /* -webkit-box-shadow: 0 15px 10px rgba(0,0,0, 0.2);   
  -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2); */
}

/* Slides */
.mySlides {
  display: none;
  padding: 8px;
  text-align: center;
}

.mySlides p {
  font-size: 16px;
  color: white;
  margin: auto 0;
  /* font-family: Impact, Charcoal, sans-serif; */
  line-height: 0.9;
}

.slideText {
  text-align: start;
  max-width: 50%;
}

.slideText h2 {
  color: white;
  font-weight: bold;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgb(6, 158, 243);
  color: white;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;
  /* background: rgba(147, 217, 250, 0.459); */
  background: rgba(6, 158, 243);
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: rgb(64, 195, 255);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */

* {
  box-sizing: border-box;
}

.horiz {
  display: flex;
  text-decoration: none;
  color: white;
  justify-content: center;
  align-items: center;
}

.horiz h1 {
  border-radius: 5px;
  font-size: 20px;
  /* padding: 14px 16px; */
}

.textPlus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  display: flex;
  width: 98%;
  margin: auto;
  margin-top: 10px;
  /* justify-content: center;
  align-items: center; */
}

.segments {
  display: flexbox;
  flex: 1;
  /* justify-content: center;
  align-content: center; */
  text-align: center;
  margin-bottom: 10px;
  color: rgb(36, 36, 36);
  border-radius: 5px;
  overflow-x: auto;
  animation: slideInFromBottom 2s, fadein 4s;
}

.segments.radios {
  background-color: white;
  box-shadow: 0 0 8px rgb(50, 175, 231);
  background: #e6fcff;
}

.segments.everyone {
  background: white;
  box-shadow: 0 0 8px rgb(167 254 129);
  margin-left: 10px;
  background: #f0fff6;
}

.segmentContent {
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}

.segmentContent ul {
  text-align: left;
}

.aboutSegmentContent {
  text-align: left;
  display: inline-block;
}

.ftNote {
  color: rgb(114, 116, 116);
  font-size: 10px;
  text-align: left;
}

.more {
  border-radius: 10px;
  background-color: #21b1f3;
  box-shadow: 5px 3px #888888;
  margin: 20px 50px;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.more a {
  text-decoration: none;
  color: white;
}

.policies {
  margin: 0 10px;
}

.policies a {
  text-decoration: none;
  color: #888;
}

.blueText {
  color: #21b1f3 !important;
}

.review {
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  box-shadow: -5px 3px #888888;
  margin: 0 8px 20px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 300px;
}
.review > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reviews {
  width: 100%;
  overflow: auto;
}

.res-vid {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.res-vid iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.features {
  margin-left: 10px;
}
.features .horizFlex {
  margin-bottom: 10px;
}

.features .horizFlex h2 {
  border-right: rgb(188 188 188) solid 2px;
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 10px;
  border-radius: 1px;
}

.features span {
  color: lightslategray;
  font-size: small;
}

.features img {
  padding: 10px;
  border-radius: 4px;
  background-color: #7cdcff;
}
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  text-align: center;
}

div.scrollmenu a {
  display: inline-block;
  color: black;
  text-align: center;
  text-decoration: none;
  margin: 4px;
  border-radius: 4px;
  background-color: white;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  box-shadow: 0 3px 3px rgb(0 0 0 / 30%);
}

div.scrollmenu a:hover {
  background-color: rgb(222, 229, 233);
}

#radios {
  margin-bottom: 20px;
}

#radios h3 {
  text-align: center;
}
.btnOpen {
  /* float: left; */
  flex: 1;
  margin: auto 0;
  font-size: 18px;
  color: white;
  background: rgba(71, 67, 214, 0);
  border: none;
  /* padding: 20px; */
  cursor: pointer;
  height: 100%;
  min-width: 65px;
  text-shadow: 2px 2px 4px #000000;
}

/* #btnTranslate{
  background-image: url("../images/web.svg");
  background-position: left;
  background-repeat: no-repeat;
  padding-right: 5px;

} */
.btnOpen:hover {
  background: rgb(0, 151, 221);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  font-size: 16px;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}
.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
.dropdown-content a.set {
  color: #0087ff;
}

.stSection {
  border-radius: 8px;
  position: sticky;
  top: 64px;
  align-self: flex-start;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  min-width: 250px;
  margin-right: 20px;
}
.stSection ul {
  list-style-type: none;
  margin: 0;
  padding: 8px;
  width: max-content;
}
.stSection li {
  border-bottom: 2px solid white;
  margin-bottom: 8px;
}

.stSection li a {
  color: black;
  display: inline-block;
  width: 100%;
  border-radius: 0 14px 14px 0;
  margin-right: 20px;
  padding: 4px;
  text-decoration: none;
}
.stSection ul li a:hover:not(.active) {
  color: #0087ff;
  background: #efefef9c;
}
#history,
#mission,
#privacy,
#infoCol,
#logData,
#sp,
#reasons,
#security,
#terms,
#contact,
#cookies,
#coins-policy,
#gifts-policy,
#withdrawal-policy {
  display: block;
  height: 64px;
  margin-top: -64px;
  visibility: hidden;
}
.active,
.dot:hover {
  background: rgb(154 223 254/30%);
  color: #06f;
}
.sponsor-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: auto;
}
.sponsor-list a:hover {
  color: #06f;
}

.sponsor-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sponsor-img {
  max-width: 200px;
}
.verticalFlex.spSocials i {
  margin-right: 8px;
}
.verticalFlex.spSocials a {
  font-size: 12px;
  margin-bottom: 4px;
}

@media screen and (max-width: 600px) {
  .info {
    display: block;
  }
  .segments.everyone {
    margin-left: 0;
  }

  .textPlus {
    flex-direction: column;
  }

  .features .horizFlex {
    flex-direction: column;
    text-align: center;
  }

  .features img {
    width: 25%;
    height: auto;
  }
  .features .horizFlex h2 {
    border-right: none;
    border-bottom: rgb(16, 156, 221) solid 5px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .textPlus {
    flex-direction: column;
  }

  .features .horizFlex {
    flex-direction: column;
    text-align: center;
  }

  .features img {
    width: 25%;
    height: auto;
  }
  .features .horizFlex h2 {
    border-right: none;
    border-bottom: rgb(16, 156, 221) solid 5px;
  }
}
