.logo {
  width: 50px;
  height: 50px;
}

body {
  flex-direction: column;
  margin: 0;
  font-family: Mulish, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: flex;
}

main {
  color: #32373b;
  background-color: #fff;
  margin-top: 50px;
}

main.horizFlex {
  margin-top: 64px;
}

main .notFound {
  text-align: center;
  margin-top: 60px;
}

a:hover {
  opacity: .8;
  color: #fff;
}

.affiliate:hover {
  color: #06f;
}

footer {
  height: 40px;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

header {
  z-index: 10;
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 50px;
  animation: 16s infinite rainbow;
  display: flex;
  position: fixed;
  top: 0;
}

.topnav, .resNav {
  flex: 1;
  align-self: flex-end;
  margin: auto 0;
  display: flex;
  overflow: hidden;
}

.topnav a, .resNav a, .topnav #btnTranslate {
  color: #000;
  text-align: center;
  cursor: pointer;
  border-radius: 20px 20px 0 0;
  flex: 1;
  margin-top: 8px;
  padding: 12px 16px;
  font-size: medium;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.topnav a {
  margin-right: 8px;
}

.topnav a:not(.active), .topnav #btnTranslate {
  border-radius: 20px;
}

.topnav a.active {
  margin-top: 8px;
}

.topnav a:hover, .resNav a:hover, .horiz :hover, .topnav #btnTranslate:hover {
  color: #fff;
  background-color: #40c3ff;
}

.topnav {
  margin-left: 8px;
}

@keyframes rainbow {
  from, to {
    box-shadow: 0 0 15px red;
  }

  16% {
    box-shadow: 0 0 15px #ff0;
  }

  32% {
    box-shadow: 0 0 15px green;
  }

  48% {
    box-shadow: 0 0 15px #0ff;
  }

  64% {
    box-shadow: 0 0 15px #00f;
  }

  80% {
    box-shadow: 0 0 15px #f0f;
  }
}

.topnav a.active, .resNav a.active {
  color: #fff;
  background-color: #21b1f3;
  border-radius: 20px;
}

.topnav .icon {
  display: none;
}

#resTopnav {
  z-index: 1;
  background-color: #000;
  background-image: linear-gradient(to right, #9fd17b, #40c3ff, #fe7be6);
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
}

#closeNav {
  text-align: right;
  cursor: pointer;
  padding-right: 20px;
  font-size: x-large;
  display: none;
}

#closeNav:hover {
  color: #242424;
  background-color: #ebc385;
}

@media screen and (width <= 600px) {
  .topnav a {
    display: none;
  }

  .topnav .icon {
    float: right;
    display: block;
  }

  .info {
    display: block;
  }
}

video {
  width: 100%;
  height: 300px;
}

.slideshow-container img {
  height: 200px;
  margin: auto;
}

.slideshow-container {
  background: #21b1f3;
  height: 200px;
  animation: 4s fadein;
  position: relative;
  overflow: hidden;
}

.mySlides {
  text-align: center;
  padding: 8px;
  display: none;
}

.mySlides p {
  color: #fff;
  margin: auto 0;
  font-size: 16px;
  line-height: .9;
}

.slideText {
  text-align: start;
  max-width: 50%;
}

.slideText h2 {
  color: #fff;
  font-weight: bold;
}

.prev, .next {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 3px 3px 0;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 50%;
}

.next {
  border-radius: 3px 0 0 3px;
  position: absolute;
  right: 0;
}

.prev:hover, .next:hover {
  color: #fff;
  background-color: #069ef3;
}

.dot-container {
  text-align: center;
  background: #069ef3;
  padding: 20px;
}

.dot {
  cursor: pointer;
  background-color: #40c3ff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  transition: background-color .6s;
  display: inline-block;
}

* {
  box-sizing: border-box;
}

.horiz {
  color: #fff;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.horiz h1 {
  border-radius: 5px;
  font-size: 20px;
}

.textPlus {
  justify-content: center;
  align-items: center;
  display: flex;
}

.info {
  width: 98%;
  margin: 10px auto auto;
  display: flex;
}

.segments {
  display: flexbox;
  text-align: center;
  color: #242424;
  border-radius: 5px;
  flex: 1;
  margin-bottom: 10px;
  animation: 2s slideInFromBottom, 4s fadein;
  overflow-x: auto;
}

.segments.radios {
  background: #e6fcff;
  box-shadow: 0 0 8px #32afe7;
}

.segments.everyone {
  background: #f0fff6;
  margin-left: 10px;
  box-shadow: 0 0 8px #a7fe81;
}

.segmentContent {
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
}

.segmentContent ul {
  text-align: left;
}

.aboutSegmentContent {
  text-align: left;
  display: inline-block;
}

.ftNote {
  color: #727474;
  text-align: left;
  font-size: 10px;
}

.more {
  text-align: center;
  cursor: pointer;
  background-color: #21b1f3;
  border-radius: 10px;
  margin: 20px 50px;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 5px 3px #888;
}

.more a {
  color: #fff;
  text-decoration: none;
}

.policies {
  margin: 0 10px;
}

.policies a {
  color: #888;
  text-decoration: none;
}

.blueText {
  color: #21b1f3 !important;
}

.review {
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff;
  border-radius: 5px;
  min-width: 300px;
  margin: 0 8px 20px;
  padding: 10px;
  overflow: hidden;
  box-shadow: -5px 3px #888;
}

.review > p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.reviews {
  width: 100%;
  overflow: auto;
}

.res-vid {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.res-vid iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.features {
  margin-left: 10px;
}

.features .horizFlex {
  margin-bottom: 10px;
}

.features .horizFlex h2 {
  border-right: 2px solid #bcbcbc;
  border-radius: 1px;
  margin-left: 10px;
  margin-right: 5px;
  padding-right: 5px;
}

.features span {
  color: #789;
  font-size: small;
}

.features img {
  background-color: #7cdcff;
  border-radius: 4px;
  padding: 10px;
}

div.scrollmenu {
  white-space: nowrap;
  text-align: center;
  overflow: auto;
}

div.scrollmenu a {
  color: #000;
  text-align: center;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background-color: #fff;
  border-radius: 4px;
  margin: 4px;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 3px 3px #0000004d;
}

div.scrollmenu a:hover {
  background-color: #dee5e9;
}

#radios {
  margin-bottom: 20px;
}

#radios h3 {
  text-align: center;
}

.btnOpen {
  color: #fff;
  cursor: pointer;
  text-shadow: 2px 2px 4px #000;
  background: #4743d600;
  border: none;
  flex: 1;
  min-width: 65px;
  height: 100%;
  margin: auto 0;
  font-size: 18px;
}

.btnOpen:hover {
  background: #0097dd;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  z-index: 10;
  background-color: #f1f1f1;
  min-width: 160px;
  font-size: 16px;
  display: none;
  position: fixed;
  right: 0;
  overflow: auto;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover, .dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.dropdown-content a.set {
  color: #0087ff;
}

.stSection {
  border-radius: 8px;
  align-self: flex-start;
  min-width: 250px;
  max-height: calc(100vh - 64px);
  margin-right: 20px;
  position: sticky;
  top: 64px;
  overflow-y: auto;
}

.stSection ul {
  width: max-content;
  margin: 0;
  padding: 8px;
  list-style-type: none;
}

.stSection li {
  border-bottom: 2px solid #fff;
  margin-bottom: 8px;
}

.stSection li a {
  color: #000;
  border-radius: 0 14px 14px 0;
  width: 100%;
  margin-right: 20px;
  padding: 4px;
  text-decoration: none;
  display: inline-block;
}

.stSection ul li a:hover:not(.active) {
  color: #0087ff;
  background: #efefef9c;
}

#history, #mission, #privacy, #infoCol, #logData, #sp, #reasons, #security, #terms, #contact, #cookies, #coins-policy, #gifts-policy, #withdrawal-policy {
  visibility: hidden;
  height: 64px;
  margin-top: -64px;
  display: block;
}

.active, .dot:hover {
  color: #06f;
  background: #9adffe4d;
}

.sponsor-list {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
  overflow: auto;
}

.sponsor-list a:hover {
  color: #06f;
}

.sponsor-item {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.sponsor-img {
  max-width: 200px;
}

.verticalFlex.spSocials i {
  margin-right: 8px;
}

.verticalFlex.spSocials a {
  margin-bottom: 4px;
  font-size: 12px;
}

@media screen and (width <= 600px) {
  .info {
    display: block;
  }

  .segments.everyone {
    margin-left: 0;
  }

  .textPlus {
    flex-direction: column;
  }

  .features .horizFlex {
    text-align: center;
    flex-direction: column;
  }

  .features img {
    width: 25%;
    height: auto;
  }

  .features .horizFlex h2 {
    border-bottom: 5px solid #109cdd;
    border-right: none;
  }
}

@media (width >= 768px) and (width <= 1024px) {
  .textPlus {
    flex-direction: column;
  }

  .features .horizFlex {
    text-align: center;
    flex-direction: column;
  }

  .features img {
    width: 25%;
    height: auto;
  }

  .features .horizFlex h2 {
    border-bottom: 5px solid #109cdd;
    border-right: none;
  }
}

/*# sourceMappingURL=index.9377e34d.css.map */
